<template>
  <main
    class="
      py-28
      xl:px-24
      lg:px-24
      md:px-20
      px-0
      text-legend-gray
      dark:text-border-color
    "
  >
    <section
      class="
        xl:w-1/2
        lg:w-3/5
        md:w-3/5
        w-11/12
        text-center
        mx-auto
        bg-white
        rounded-md
        shadow-md
        py-12
        xl:px-16
        lg:px-12
        md:px-12
        px-6
      "
    >
      <img
        src="@/assets/images/confetti-cup.svg"
        alt="electricity token generated"
        class="inline-block mx-auto mb-6"
      />
      <h1 class="text-2xl font-bold">Ta-Da!</h1>
      <h2 class="text-xl font-bold my-3">Transaction Completed</h2>
      <p>Here are the details of your transaction.</p>
      <div class="mt-12 mb-10 relative">
        <label for="" class="block text-left font-semibold capitalize"
          >meter token</label
        >
        <input
          v-model="result.token"
          type="text"
          readonly
          class="
            border border-border-color
            rounded-md
            p-2
            block
            w-full
            focus:outline-none
          "
        />
        <button
          class="
            bg-primary-color
            text-white
            py-1.5
            px-3
            rounded-md
            absolute
            top-8
            right-2
            text-xs
          "
        >
          Copy
        </button>
      </div>
      <div class="capitalize my-2 flex justify-between">
        <span class="capitalize font-semibold mr-2">name:</span
        ><span>{{ result.meter_name }}</span>
      </div>
      <div class="capitalize my-2 flex justify-between">
        <span class="capitalize font-semibold mr-2">meter number:</span
        ><span>{{ result.meter_number }}</span>
      </div>
      <div class="capitalize my-2 flex justify-between">
        <span class="capitalize font-semibold mr-2">amount:</span
        ><span>N{{ result.amount }}</span>
      </div>
      <div class="capitalize my-2 flex justify-between">
        <span class="capitalize font-semibold mr-2">address:</span
        ><span>{{ result.address }}</span>
      </div>
      <div class="capitalize my-2 flex justify-between">
        <span class="capitalize font-semibold mr-2">units:</span
        ><span>{{ result.token_units }}</span>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'ElectricityToken',
  mounted() {
    this.result = JSON.parse(this.$route.query.result)
  },
  data() {
    return {
      result: null,
    }
  },
}
</script>
